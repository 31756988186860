/**
 * Vuex Store Getters
 */

export const navState = state => {
	return state.nav.open
}

export const navItems = state => {
	return state.nav.items
}

export const contact = state => {
	return state.contact
}

export const apiState = state => {
	return state.apiState
}

export const work = state => {
	return state.work
}

export const loading = state => state.loading

export const scroll = state => state.scroll