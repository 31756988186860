/**
 * Vuex Store
 *
 * @requires vue
 * @requires vuex
 */

// Dependencies
import Vue from 'vue'
import Vuex from 'vuex'

// Store Components
import * as getters from './getters'
import * as actions from './actions'
import mutations from './mutations'

// Install
Vue.use(Vuex)

// Set State Objects
const state = {
	// Nav
	nav: {
		open: false,
		items: {
			agency: {
				link: 'work',
				number: '02.',
				label: 'Agency',
				title: 'About Us'
			},
			capabilities: {
				link: 'expertise',
				number: '03.',
				label: 'Expertise',
				title: 'This is what we did'
			},
			work: {
				link: 'work',
				number: '04.',
				label: 'Case Studies',
				title: 'Case Studies'
			},
			contact: {
				link: 'contact',
				number: '05.',
				label: 'Contact Us',
				title: 'Holla!'
			},
		},
	},

	// Loading
	loading: {
		state: true,
		count: 1,
		delay: 1000,
	},

	scroll: {
		pos: 0
	},

	// Contact Form
	contact: {
		success: null,
		fail: null,
		loading: false,
		data: {},
	},

	// API
	apiState: false,

	// Work
	work: {
		loading: false,
		data: [],
	}
}

// Modules
import modules from './modules'

// Export
export default new Vuex.Store({
	modules,
	state,
	getters,
	actions,
	mutations
})
