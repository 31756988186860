/**
 * Vue Meta
 *
 * @requires vue
 * @requires vue-meta
 */

// Dependencies
import Vue from 'vue'
import Meta from 'vue-meta'

// Install
Vue.use(Meta)
