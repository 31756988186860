/**
 * Vuex Module - Nav
 */

// Dependencies
import * as types from '@/lib/store/types'

// State
const state = {
	nav: {
		open: true,
		internal: {
			home: {
				label: 'Home',
				title: 'Go Home',
				link: '/',
				inHeader: true,
				inFooter: false,
			},
			trust: {
				label: 'Trust Services',
				title: 'Trust Services',
				link: 'trust-services',
				inHeader: true,
				inFooter: true,
			},
			wealth: {
				label: 'Wealth Management Services',
				title: 'Wealth Management',
				link: 'estate-planning',
				inHeader: true,
				inFooter: true,
			},
			contact: {
				label: 'Contact Us',
				title: 'Talk to Us',
				link: 'contact',
				inHeader: true,
				inFooter: true,
			},
			team: {
				label: 'Team',
				title: 'Team',
				link: 'team',
				inHeader: false,
				inFooter: true,
			},
			blog: {
				label: 'Blog',
				title: 'Blog of the Ozarks',
				link: 'https://blog.bankozarks.com',
				inHeader: true,
				inFooter: false,
			},
			login: {
				label: 'Log In',
				title: 'Log In to Bank of the Ozarks',
				link: 'https://bankozarks.com/login',
				class: 'login',
				inHeader: true,
				inFooter: true,
			},
		},
		external: {
			career: {
				label: 'Career Opportunities',
				title: 'Build your careero',
				link: 'https://bankozarks.com',
				inFooter: true,
			},
			boto: {
				label: 'Bank of the Ozarks',
				title: 'Bank of the Ozarls',
				link: 'https://bankozarks.com',
				inFooter: true,
			},
			learning: {
				label: 'Learning Center',
				title: 'Learning Center',
				link: 'https://bankozarks.com',
				inFooter: true,
			},
		},
	},
}

// Actions
const actions = {
	navToggle: ({ commit }, toggle) => {
		commit(types.NAV_TOGGLE, toggle)
	},
}

// Mutations
const mutations = {
	[types.NAV_TOGGLE](state, toggle) {
		if (typeof toggle === 'undefined') {
			state.nav.open = !state.nav.open
		} else {
			state.nav.open = toggle
		}
	},
}

// Getters
const getters = {
	nav: state => state.nav,
	navOpen: state => state.nav.open,
	navInternal: state => state.nav.internal,
	navExternal: state => state.nav.external,
}

export default {
	state,
	actions,
	mutations,
	getters,
}
