/**
 * Vue Router
 *
 * @requires vue
 * @requires vue-router
 */

// Dependencies
import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'

// Install
Vue.use(VueRouter)

// Setup
const router = new VueRouter({
	mode: 'history',
	routes,
	scrollBehavior (to, from, savedPosition) {
		if (to.hash !== from.hash && to.name === from.name) return
		return { x: 0, y: 0 }
	}
})

export default router
