/**
 * Vuex Store Mutations
 */

import Vue from 'vue'
import * as types from './types'

export default {
	[types.NAV_TOGGLE] (state, toggle)
	{
		if( toggle === undefined ) {
			state.nav.open = !state.nav.open
		} else {
			state.nav.open = toggle
		}
	},

	[types.CONTACT_SUCCESS] (state, res)
	{
		state.contact.success = res
	},

	[types.CONTACT_FAIL] (state, err)
	{
		state.contact.fail = err
	},

	[types.CONTACT_LOADING] (state, loading)
	{
		state.contact.loading = loading
	},

	[types.CONTACT_DATA] (state, data)
	{
		state.contact.data = data
	},

	[types.API_INIT] (state, status)
	{
		state.apiState = status
	},

	[types.WORK_LIST] (state, data)
	{
		state.work.data = data
	},

	[types.WORK_LOADING] (state, loading)
	{
		state.work.loading = loading
	},

	[types.GLOBAL_LOADING] (state, loading)
	{
		if( loading ) {
			state.loading.count++
		} else {
			state.loading.count = Math.max(0,state.loading.count-1)
		}

		let currentState = !!state.loading.count
		if( !currentState ) {
			setTimeout( () => {
				Vue.set(state.loading, 'state', currentState)
			}, 400)
		} else {
			Vue.set(state.loading,'state',currentState)
		}
		// state.loading.state = !!state.loading.count
	},

	[types.SET_SCROLL] (state, pos)
	{
		Vue.set(state.scroll, 'pos', pos)
	},
}