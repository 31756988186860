/**
 * Vuex Store Actions
 */

import * as types from './types'

export const navToggle = ({commit}, toggle) => {
	commit(types.NAV_TOGGLE, toggle)
}

export const setLoading = ({ commit }, loading) => {
	if (!loading) {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				commit(types.GLOBAL_LOADING, loading)
				resolve(loading)
			}, 1)
		})
	} else {
		commit(types.GLOBAL_LOADING, loading)

		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve(loading)
			}, 1000)
		})
	}
}

export const setScroll = ({commit}, pos) => {
	return commit(types.SET_SCROLL, pos)
}