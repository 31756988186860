<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'app',
  metaInfo: () => ({
    title: 'Mike Parsons Ink',
    titleTemplate: '%s | Award Winning Tattoos and Custom Art | Tampa Florida',
meta: [
			{
				charset: 'utf-8',
			},
			{
				'http-equiv': 'X-UA-Compatible',
				content: 'IE-edge',
			},
			{
				name: 'description',
				content: 'Award winning Tattoos and Custom Art by Mike Parsons - Tampa, FL Tattoo Artist',
			},
			{
				name: 'viewport',
				content: 'width=device-width, initial-scale=1, maximum-scale=5',
			},
		],
		script: [
			{
				type: 'application/ld+json',
				innerHTML: '{"@context":"http://www.schema.org","@type":"TattooParlor","name":"Mike Parsons Ink","url":"https://www.mikeparsonsink.com","telephone":"+18133742146","sameAs":["http://twitter.com/mikeparsonsink","https://www.instagram.com/explore/locations/1015067666/?hl=en","http://www.facebook.com/pages/Mike-Parsons-Ink/102848876424242"],"logo":"http://www.mikeparsonsink.com/img/mikeparsonsinklogo.png","image":"http://www.mikeparsonsink.com/img/mikeparsonsinklogo.png","description":"Mike Parsons Ink Tattoo Shop - Award Winning Tattoos & Custom Art","address":{"@type":"PostalAddress","streetAddress":"13346 Lincoln Rd","addressLocality":"Riverview","addressRegion":"Florida","postalCode":"33578","addressCountry":"United States"},"geo":{"@type":"GeoCoordinates","latitude":"27.790326","longitude":"-82.342677"},"hasMap":"https://www.google.com/maps/place/Mike+Parsons+Ink/@27.790326,-82.342677,10z/data=!4m5!3m4!1s0x0:0xab6cff195e78c7b0!8m2!3d27.7903257!4d-82.3426771?hl=en","openingHours":"Tu,We,Th,Fr,Sa 12:00-20:00","contactPoint":{"@type":"ContactPoint","telephone":"+18133742146","contactType":"reservations"}}',
			},
		],
		link: [
			{
				rel: 'icon',
				href: require('@/assets/images/favicon-96x96.png'),
				type: 'image/png',
			},
			{
				rel: 'apple-touch-icon',
				href: require('@/assets/images/apple-touch-icon-152x152.png'),
				type: 'image/png',
			},
			{
				rel: 'manifest',
				href: '/manifest.json',
			},
			{
				rel: 'stylesheet',
				href: 'https://fonts.googleapis.com/css?family=Cormorant:400,500,600,700'
			}
    ],
  }),
}
</script>

<style lang="scss">
@import './styles/main';
#app {
	display: flex;
	flex: 1;
	height: 100%;
}
</style>
