
export const NAV_TOGGLE = 'NAV_TOGGLE'

// Contact Form
export const CONTACT_SUCCESS = 'CONTACT_SUCCESS'
export const CONTACT_FAIL = 'CONTACT_FAIL'
export const CONTACT_LOADING = 'CONTACT_LOADING'
export const CONTACT_DATA = 'CONTACT_DATA'

// API
export const API_INIT = 'API_INIT'

// Work
export const WORK_LIST = 'WORK_LIST'
export const WORK_LOADING = 'WORK_LOADING'

// Loading
export const GLOBAL_LOADING = 'GLOBAL_LOADING'
export const SET_SCROLL = 'SET_SCROLL'
